import React from "react";
import "./CEOQuotes.css"; // Import your CSS for CEOQuotesMarquee styling
import { baseUrl } from "../../config";

const CEOQuotes = ({ photoUrl, quote, author }) => {
  return (
    <div className="ceo-quotes" id="ceo-quotes">
      <div className="container">
        <h2 className="section-title">CEO Message</h2>
        <div className="ceo-quotes-content">
          <div className="ceo-quotes-left">
            <img
              src={`${baseUrl}/images/CEO.png`}
              alt="CEO"
              className="ceo-photo"
            />
          </div>
          <div className="ceo-quotes-right">
            <div className="quote-item">
              <p className="quote-text">
                &quot;
                {"Success is built on "}
                <strong>1% vision and 99% hard work</strong>
                {
                  ". Stay focused, stay driven, and let's turn our ideas into achievements."
                }
                &quot;
              </p>
              <p className="quote-author">- {"Renu, CEO of GeniusNexa"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CEOQuotes;
